import {createApp} from 'vue'
import {createI18n} from 'vue-i18n';
import App from './App.vue'
import router from './router'
import store from './store'
import wait from "@meforma/vue-wait-for";
import './assets/tw.css'
import './assets/fontawesome.css'
import deMessages from './locales/de.json';
import frMessages from './locales/fr.json';
import './registerServiceWorker'

const app = createApp(App)

const i18n = createI18n({
    locale: 'de',
    messages: {
        de: deMessages,
        fr: frMessages
    }
})

app.use(store)
    .use(router)
    .use(i18n)
    .use(wait)
    .mount('#app')
