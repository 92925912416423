import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

const routes = [
  {
    path: '',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    redirect: '/start',
    children: [
      {
        path: '/',
        name: 'Start',
        component: () => import('@/views/Index.vue'),
      },
      {
        path: 'page/:slug',
        name: 'Page',
        component: () => import('@/views/Page'),
        alias: '/:slug'
      },
      {
        path: '/blog',
        name: 'Blog',
        component: () => import('@/views/Blog/Blog'),
        alias: '/blog/:slug'
      },
      {
        path: '/discovery/:slug',
        name: 'Blog Detail',
        component: () => import('@/views/Blog/BlogDetail')
      },
      {
        path: '/chat',
        name: 'Chat Start',
        component: () => import('@/views/Chat/ChatStart')
      },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/chat-admin') {
    if (!store.state.accessToken) {
      next('/chat-login');
    }
  }
  if (to.fullPath === '/chat-admin-show/') {
    if (!store.state.accessToken) {
      next('/chat-login');
    }
  }
  next();
});

export default router
