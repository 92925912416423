<template>
  <div class="bg-bg_creme_light min-h-screen body1">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    }
  },
  components: {
  },
  created() {
  },
}

</script>

<style lang="scss">
@import './assets/sass/app.scss';
</style>
